import "./NotFound.css";
import logoImg from "../../Media/logo/RADS_ChainLogo.svg";

export default function NotFound(props) {

	return (
		<div className="not-found">
			<div className="not-found-container">
				<img
					className="not-found-logo-img"
					src={logoImg}
                    alt=""
				/>

				<h1 className="not-found-heading">page not found</h1>

				<div className="not-found-spacer" />
			</div>
		</div>
	);
};