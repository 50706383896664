import "./GalleryImg.css";

export default function GalleryImg(props) {
	return (
		<div className="gallery-img-container">
			<img
				className="gallery-img"
				src={props.imgSrc}
				key={props.imgSrc}
				loading="lazy"
				alt=""

				onClick={props.onClick}
			/>
		</div>
	);
}