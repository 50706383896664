import { useEffect, useState } from "react";
import GalleryImg from "../../Components/GalleryImg/GalleryImg";
import "./Gallery.css";

export default function Gallery(props) {
    const images = require.context("../../Media/Gallery", true);

    const [fullSizeSrc, setFullSizeSrc] = useState("");
    const [showFullSize, setShowFullSize] = useState(false);

    const selectFullSizeSrc = (event) => {
        setFullSizeSrc(event.target.src);
        document.body.style.overflow = "hidden";
    }

    const closeFullSize = (event) => {
        if (event.target.nodeName !== "IMG") {
            setFullSizeSrc("");
        }
        document.body.style.overflow = "scroll";
    };

    useEffect(() => {
        if (fullSizeSrc) {
            setShowFullSize(true);
        } else {
            setShowFullSize(false);
        }
    }, [fullSizeSrc]);

    useEffect(() => {
        document.body.style.overflow = "scroll";

        return () => {
            document.body.style.overflow = "hidden";
        }
    }, []);

    return (
        <div className="gallery">
            <div className="gallery-container">
                {images.keys().map(image => images(image)).map((image, index) => (
                    <GalleryImg
                        key={index}
                        imgSrc={image}
                        onClick={selectFullSizeSrc}
                    />
                ))}
            </div>

            <div className={`gallery-fullsize-container ${showFullSize ? "visible" : ""}`} onClick={closeFullSize}>
                <div className="gallery-fullsize-img-container">
                    <img
                        className="gallery-fullsize-img"
                        src={fullSizeSrc}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
}