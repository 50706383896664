import { Route, Routes } from 'react-router-dom';
import './App.css';
import Gallery from './Content/Gallery/Gallery';
import Navigation from './Content/Navigation/Navigation';
import Upcoming from './Content/Upcoming/Upcoming';
import Contact from './Content/Contact/Contact';
import NotFound from './Content/NotFound/NotFound';

function App() {
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Navigation />} />
				<Route path="/archive" element={<Gallery />} />
				<Route path="/upcoming" element={<Upcoming />} />
				<Route path="/shop" element={<NotFound/>} />

				{/* <Route path="/contact" element={<Contact />} /> */}

				

				<Route path="*" element={<NotFound/>} />
			</Routes>
		</div>
	);
}

export default App;
