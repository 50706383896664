import "./Upcoming.css";
import posterImg from "../../Media/Upcoming/RADS_Rhiz_Fin1-min.webp";


export default function Upcoming(props) {

	return (
		<div className="upcoming">

			<img
				className="upcoming-poster-img"
				src={posterImg}
				alt=""
			/>

		</div>
	);
};